.contact-links {
  position: absolute;
  width: 100vw;
  height: calc(100vh + 2rem);
  justify-content: center;
  align-items: center;
  margin: -14rem -2rem -2rem -2rem;
  background-image: url("./img/main-logo-gradient.png");
  background-repeat: repeat-y;
  background-position-x: center;
  /* background-size: 900px; */
}

.contact-links a {
  text-decoration: none;
  color: var(--main-txt-color);
  font-family: "alice-drive";
  font-size: 4rem;
  height: 5rem;
  display: flex;
  align-items: center;
}
.contact-links a:hover {
  font-family: "alice-robot";
  font-size: 2.3rem;
}
.contact-links a:first-of-type {
  transform: translateX(-3rem);
}

.contact-links a:last-of-type {
  transform: translateX(3rem);
}

.contact-links a:hover::before {
  content: "";
  position: absolute;

  left: -0.5rem;
  top: 1.6rem;

  width: 0;
  position: absolute;

  height: 0;
  margin-left: -24px;
  vertical-align: middle;

  border-left: 1rem solid var(--main-txt-color);
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
}
