.bio-container {
  width: 100vw;
  margin-left: -2rem;
  margin-bottom: -2rem;
  height: calc(100vh - 12rem);
}

.bio-draggable-element {
  cursor: grab;
  width: fit-content;
  height: fit-content;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.4);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.bio-draggable-element:hover {
  transform: translate(0.1rem, 0.1rem);
}
.bio-draggable-element.moving {
  cursor: grabbing;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.6);
}

.bio-id-img {
  height: 28rem;
  height: fit-content;
}
.bio-id-img img {
  width: 100%;
}

.bio-cam-gif {
  background-color: rgb(181, 181, 181);
  border: solid 5px rgb(123, 123, 123);
  width: 50rem;
  height: 39rem;
}

.bio-cam-gif img {
  width: 100%;
}
.row-sub_img {
  display: flex;
  justify-content: center;
  height: 5rem;
  align-items: center;
  border-top: solid 2px #ddd;
  border-right: solid 1px black;
}
.sub_img {
  width: 5rem !important;
  height: 2rem;
}

.bio-text {
  background-color: white;
  border: solid 5px rgb(123, 123, 123);
  color: black;
  height: 35rem;
  width: 26rem;
}
.bio-text > img {
  width: 100%;
}
.bio-text-content {
  padding: 0 1rem;
  font-family: "alice-drive";
  font-size: large;
  height: 28.7rem;
  overflow-y: scroll;
}
.bio-text-content > p:first-of-type {
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
}

.bio-text-content > p {
  user-select: none;
}
