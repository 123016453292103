.menu-button {
  pointer-events: all;
  width: 3rem;
  height: 3rem;
  z-index: 1;
}

.menu-button .tiny-menu-bar {
  width: 3rem;
  height: 0.5rem;
  background-color: var(--main-txt-color);
  margin-bottom: 0.8rem;
  transition: transform 0.2s ease;
  z-index: 1;
}
.header-container.blended.white .menu-button .tiny-menu-bar {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 3, 255, 1) 0%,
    rgba(0, 56, 255, 1) 0%,
    rgba(255, 1, 1, 1) 100%
  );
}
.menu-button .tiny-menu-bar:last-of-type {
  margin-bottom: 0;
}
.menu-button.opened .tiny-menu-bar:last-of-type {
  transform: translateY(-1.2rem) rotate(45deg);
}
.menu-button.opened .tiny-menu-bar:first-of-type {
  transform: translateY(1.3rem) rotate(-45deg);
}
.menu-button.opened .tiny-menu-bar:nth-last-of-type(2) {
  transform: scale(0);
}

.menu-list-container {
  pointer-events: all;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: black;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

.menu-list-container.hidden {
  /* opacity: 0; */
  transform: translateY(-200vh);
}

.menu-list-container ul > *:not(.menu-title) {
  background-color: var(--secondary-txt-color);
  color: black;
  margin: 1rem 0;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.3rem;
  position: relative;
  text-decoration: none;
  font-family: "alice-robot-bold";
  transition: transform 0.2s ease;
  width: 20rem;
}

.menu-list-container ul > :not(.selected):not(.menu-title):hover {
  transform: translateX(-0.3rem);
  background-color: var(--main-txt-color);
}

.menu-list-container ul > .selected {
  cursor: default;
}

.menu-list-container ul > .selected::before {
  content: "";
  position: absolute;

  left: -0.5rem;
  top: 0.32rem;

  width: 0;
  position: absolute;

  height: 0;
  margin-left: -24px;
  vertical-align: middle;

  border-left: 1rem solid var(--main-txt-color);
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
}

h2.menu-title {
  font-size: 9.5rem;
  padding: 0;
  margin: 0;
  font-weight: 100;
  font-family: "alice-drive";
  position: relative;
  color: white;
}

.menu-title::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, blue, red);
  mix-blend-mode: multiply;
}
