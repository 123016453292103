.roller {
  pointer-events: none;
  height: 4rem;
  width: 100vw;
  overflow: hidden;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  /* background-color: black; */
  align-items: center;
  margin-top: -2rem;
  margin-left: -2rem;
  margin-bottom: 2rem;
  color: var(--main-txt-color);
  text-decoration: none;
  overflow: hidden;
}

.rolling {
  animation-name: rolling;
  animation-duration: 8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  width: auto;
  font-size: 1.6rem;
  user-select: none;
}

@keyframes rolling {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(-100%);
  }
}
