.fullsize-galery {
  margin-top: -2rem;
  margin-left: -2rem;
  margin-bottom: -2rem;
  width: 100vw;
}

.fullsize-galery img {
  width: 100vw !important;
  margin: 0 !important;
  padding: 0;
  /* height: 800px; */
}

.film-galery-item {
  position: relative;
  width: 100vw;
  overflow: hidden;
  max-height: 37rem;
}

.film-galery-item:hover > .film-hover-title {
  opacity: 1;
}

.film-galery-item > img {
  transition: transform 0.2s ease;
}

.film-hover-title {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2rem;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.film-hover-title h2 {
  font-weight: 100;
  font-size: 1rem;
}
