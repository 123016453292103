@media only screen and (max-width: 1300px) {
  .rolling {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 1100px) {
  .pub.grid-container,
  .clip.grid-container,
  .movie.grid-container {
    display: flex;
    flex-wrap: wrap;
  }
  .pub.grid-container > *,
  .clip.grid-container > *,
  .movie.grid-container > * {
    width: calc(50% - 0.5rem);
  }
  .bio-container .roller {
    display: none;
  }
  .roller, .rolling {
    width:60rem;
  }
  /* h3.img-titles {
    opacity: 1 !important;
  }
  .film-galery-item h3.img-titles {
  font-size: 1rem
  } */
  .rolling {
    /* display: none; */
  }
  .bio-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    position: absolute;
    width: 100vw;
  }
  .bio-container > .bio-draggable-element {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    width: 60vw;
    margin-bottom: 1rem !important;
    pointer-events: none;
  }
  .bio-container .bio-text {
    height: 70rem !important;
  }
  .bio-container .bio-text:hover {
    transform: translate(0px, 0px);
    cursor: auto;
  }
  .bio-text-content {
    pointer-events: all;
  }
}

@media only screen and (max-width: 710px) {
  .rolling {
    /* display: none; */
  }
  .bio-container > .bio-draggable-element {
    width: 100%;
  }

  .row-to-col {
    display: flex;
    flex-direction: column;
  }
  .pub.grid-container > *,
  .clip.grid-container > *,
  .movie.grid-container > * {
    width: 100%;
  }
}
