.grid-container {
  margin-bottom: 5rem;
  gap: 1rem !important;
}

.pub.grid-container {
  display: grid;
  grid-template-columns: 1fr 0.8fr 0.3fr 1.7fr 0.6fr 1.2fr 0.6fr 2.3fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 30px 15px;
  grid-template-areas:
    "a a b b c c d d"
    "e e e f f g g h"
    "i i j j k k k l";
}

.clip.grid-container {
  display: grid;
  grid-template-columns: repeat(1fr);
  grid-template-rows: repeat(1fr);
  padding: 0 !important;

  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
  gap: 30px 15px;
  /* grid-template-areas:
    "a a b b"
    "c d g g"
    "e f h h"
    "i i j j"
    "k k l l"
    "m n q q"
    "o p s s"
    "r r u u"
    "t t v v";
} */
  grid-template-areas:
    "a a b b"
    "c d e e"
    "f g h h"
    "i i j j"
    "k k l l"
    "m n o o"
    "p q r r"
    "s s t t"
    "u u v v";
}
/* .clip.grid-container > div {
  background-color: blue;
  width: 10px;
} */

/* Safari 10.1+ */

@media not all and (min-resolution: 0.001dpcm) {
  @media {
    .clip.grid-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .clip.grid-container > * {
      margin: 0.5rem;
      max-width: 32%;
      min-width: 200px;
    }
  }
}
.movie.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 30px 15px;
  grid-template-areas:
    "a a b b b c c"
    "d d d e e e e"
    "f f g g h h h"
    "i i i i j j j";
}
.grid-container > a {
  display: flex;
  flex-direction: row;
  align-items: center;
  animation-name: imageLoad;
  animation-duration: 0.2s;
  overflow: hidden;
}

.grid-container img {
  /* background-color: var(--secondary-txt-color); */
  background-color: black;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.pub.grid-container > a {
  height: 15rem;
}
.pub.grid-container img {
  min-height: 15rem;
}

.clip.grid-container > a,
.movie.grid-container > a {
  height: 20rem;
}

.clip.grid-container img,
.movie.grid-container img {
  min-height: 20rem;
}

@keyframes imageLoad {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
