:root {
  --main-txt-color: #21fc71;
  --secondary-txt-color: #7ffd0f;
}
html {
  font-family: Arial, Helvetica, sans-serif;
}
img {
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
ul {
  margin: 2rem 0 3rem 0;
  padding: 0;
  list-style: none;
}
@font-face {
  font-family: "alice-robot";
  src: url("./font/PIXEARG_.TTF") format("truetype");
}
@font-face {
  font-family: "alice-robot-bold";
  src: url("./font/PIXEAB.TTF") format("truetype");
}
@font-face {
  font-family: "alice-drive";
  src: url("./font/alicedrive.ttf") format("truetype");
}
* {
  box-sizing: border-box;
}
html,
body {
  background-color: black;
  color: var(--main-txt-color);
}

.parse-number {
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
}
.clip.grid-container .parse-number {
  font-size: 1.1rem;
}

.home-logo-animate {
  max-width: 50vw;
  max-height: 30vw;
  animation: fadeIn;
  animation-duration: 1s;
}

.home-go-btn {
  height: 7rem;
  opacity: 0;
  position: absolute;
  top: 65vh;
  left: calc(50% - 56px);
}
.home-go-btn.fadeIn {
  opacity: 1;
  animation-name: fadeIn;
  animation-duration: 1s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.home-go-btn img {
  height: 100%;
}
.home {
  height: calc(100vh - 15rem);
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
.background-text {
  width: 1000px;
  height: 100%;
  background-size: contain;
}
.home-logo {
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("./img/main-logo.png");
  background-position: center;
  width: 100%;
  left: 0;
  top: -200px;
  height: 100%;
  width: 100%;
  height: 100vh;
  background-size: 700px;
}
.home-links {
  height: 3rem;
  margin-right: 3rem;
  text-decoration: none;
  color: var(--main-txt-color);
}

.full-height {
  height: 100vh;
}

.home-img-effect {
  filter: grayscale(1) !important;
}

.home-links:nth-of-type(odd) {
  transform: translateY(1rem);
}

.white-content {
  color: white;
}
.sup-padding {
  padding: 0 2rem;
}

.drive-header {
  font-family: "alice-drive";
  font-size: 4rem;
  font-weight: 100;
  margin: 0 0 1rem 0;
}

.App {
  font-family: "alice-robot";
}

.col {
  display: flex;
  flex-direction: column;
}
.just-center {
  justify-content: center;
  width: 100vw;
}
.row-to-col,
.row {
  display: flex;
  flex-direction: row;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.header-container {
  pointer-events: none;
  top: 0 !important;
  padding: 2rem;
  justify-content: space-between;
  padding-bottom: 1rem;
  height: 10rem;
  width: 100vw;
  position: fixed;
  z-index: 1;
}
.header-container.white .logo::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  mix-blend-mode: color;
  top: 0;
  left: 0;
}

.blended {
  mix-blend-mode: difference;
}
.header-container .logo.hidden {
  display: none;
}
.home-full-size .logo,
.header-container .logo {
  width: 270px;
  height: 10rem;
  margin-top: -2rem;
  background-image: url("./img/logo-sm-green.png");
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}

.home-full-size .logo {
  height: 20rem;
}

.fullsize {
  min-height: calc(100vh - 10rem);
  width: 100vw;
  padding: 2rem;
  margin-top: 10rem;
}
.scrollableY {
  overflow-y: scroll;
}

img:not(.home-img):not(.no-effect) {
  transform: scale(1);
  opacity: 1;
  transition: transform 0.5s ease, opacity 0.5s ease !important;
}
img:not(.home-img):not(.no-effect):hover {
  transform: scale(1.03);
  opacity: 0.7;
}

.film-galery-item.clickable {
  position: relative;
}
.decors-img {
  position: relative;
}

.decors-img h3.img-titles {
  opacity: 0;
  font-size: 1rem;
  bottom: 1rem;
  transition: opacity 0.2s ease;
}
.decors-img:hover h3.img-titles {
  opacity: 1;
}

h3.img-titles {
  position: absolute;
  z-index: 1;
  bottom: 2rem;
  right: 2rem !important;
  font-family: "alice-drive";
  font-size: 1.5rem;
  color: var(--main-txt-color);
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}
.film-galery-item h3.img-titles {
  opacity: 0;
  transition: opacity 0.2s ease;
}
.film-galery-item:hover h3.img-titles {
  opacity: 1;
}

@keyframes imageLoad {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
